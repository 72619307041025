export type ConfType = {
  downloadPDFAndCSV: boolean;
  askIsCardLostOrStolen: boolean;
  callCenterNumber: string;
  supportEmail: string;
};

export type ConfItemType = {
  [key in keyof ConfType]?: ConfType[key];
};

const configuration: ConfType = {
  downloadPDFAndCSV: true,
  askIsCardLostOrStolen: true,
  callCenterNumber: '+44 (0) 203 773 1922',
  supportEmail: 'info@pannovate.com'
};

export default configuration;
